@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;

// Grids (choose one)
// @include foundation-xy-grid-classes;
@include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
// @include foundation-close-button;
// @include foundation-label;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
// @include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
@include foundation-dropdown;
@include foundation-pagination;
@include foundation-tooltip;

// Containers
@include foundation-accordion;
@include foundation-media-object;
@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-accordion-menu;
@include foundation-drilldown-menu;
@include foundation-dropdown-menu;

// Layout components
@include foundation-off-canvas;
@include foundation-reveal;
@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;

// Motion UI
@include motion-ui-transitions;
@include motion-ui-animations;



/* GLOBAL
========================================================================== */

html {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
}
body {
	// background: #46bfa9;
	background: #f6f6f6;
	font-family: 'Droid Sans', sans-serif;
}
.large-12.columns {
	/*border: 1px solid #ff0000;*/
}
h1, .h1 {
	color: #ffffff;
	font-family: 'ASAP', sans-serif;
	letter-spacing: 1.5px;
	line-height:normal;
	font-weight: 700;
	text-transform: uppercase;
}
h2, .h2 {
	color: #000000;
	font-family: 'ASAP', sans-serif;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	font-weight: 700;
}
h3, h4, h5, h6,
.h3, .h4, .h5, .h6 {
	font-family: 'ASAP', sans-serif;
	font-weight: 700;

}
h3, .h3 {
	font-size: 24px;
	color: #193833;
}
h4, .h4 {
	font-size: 18px;
	color: #2b7d6e;
}
h5, .h5 {
	color: #193833;
}
h6, .h6 {
	color: #006b63;
}
p {
	color: #19443c;
	font-weight: 400;
	letter-spacing: 0.0125rem;
}
a {
	color: #f3713b;
	font-weight: 700;
	&:hover {
		color: #f3713b;
		text-decoration: underline;
	}
}
p + h3, p + h4,
ul + h3, ul + h4,
ol + h3, ol + h4,
p + .h3, p + .h4,
ul + .h3, ul + .h4,
ol + .h3, ol + .h4 {
	padding-top: 30px;
}
.light-blue, .green, .spam, .orange, .yellow {
	text-shadow: 2px 2px 1px #2b7d6e;
	/*text-shadow: 2px 2px 1px #568b77;*/
}
.light-blue {
	color: #f6f6f6;
}
.green {
	color: #bdce53;
}
.mid-green {
	color: #40ad99;
}
.dark-green {
	color: #2b7d6e;
}
.spam {
	color: #ffa4bf;
}
.orange {
	color: #eeb343;
}
.yellow {
	color: #f3ce46;
}
.white {
	color: #ffffff;
}
.inline-list {
	li {
		display: inline-block;
	}
}
.small-centered {
	margin-left: auto !important;
	margin-right: auto !important;
}

/* -- HEADER -- */
header {
	background: #ffffff;
	padding: 2% 0;
	-webkit-box-shadow: 0 0 8px rgba(0,0,0,.3);
	-moz-box-shadow: 0 0 8px rgba(0,0,0,.3);
	box-shadow: 0 0 8px rgba(0,0,0,.3);
	.logo a {
		text-indent: -9000px;
		background: url("../img/header-logo.png") no-repeat top center;
		height: 92px;
		display: block;
	}
  h1, .h1 {
    margin-bottom: 0;
  }
}


/* -- FOOTER -- */
footer {
	background: #ffffff;
	color: #006b63;
	font-size: 0.875rem;
	padding: 2% 0;
	margin-top: 0;
	-webkit-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.8);
	-moz-box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.8);
	box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.8);
	ul {
		margin: 0;
		@include breakpoint(small only) {
			margin-bottom: 0.5rem;
		}
		li {
			padding: 0 20px 0 10px;
			&:not(:last-child):after {
				content: "•";
				padding-left: 20px;
				color: #006b63;
			}
			a {
				color: #006b63;
				&:hover {
					color: #006b63;
					text-decoration: underline;
				}
			}
		}
	}
	@include breakpoint(small only) {
		.columns:not(.text-left):not(.text-center):not(.text-right) {
			text-align: center;
		}
    .copyright {
      text-align: center;
    }
	}
}


/* CONTENT
========================================================================== */

#main  {
	#content {
		margin-bottom: 50px;
		h1 {
			color: #006b63;
		}
	}
}

/* -- INDEX ONLY -- */
.section-wrapper {
	&:not(.highlighted) {
		padding: 5% 0;
	}
	&.highlighted {
		background: #46bfa9;
	}
	&.app-detail {
		background: #389885;
		display: none;
	}
	&.translations {
		background: #40ad99;
	}
	&.schools {
		background: #389885 url("../img/schools-pattern.png") repeat;
		display: none;
	}
	&.stickers {
		background: #f6f6f6;
	}
	&.mailinglist {
		background:  #eeb343;
	}


	@include breakpoint(medium down) {
		.columns:not(.text-left):not(.text-center):not(.text-right) {
			text-align: center;
		}
	}

	@include breakpoint(small down) {
		.coming-soon2 img {
			display: none;
		}

		.columns .coming-soon1 {
			width: 100%;
		}
	}
}
#intro {
	.app-badge {
		display: block;
		margin: 5% 0;
		img {
			height: 52px;
			width: auto;
			@include breakpoint(small only) {
				height: 40px;
			}
		}
		&:hover {
			img {
				border-radius: 5px;
				-webkit-box-shadow: 0 0 8px rgba(0,0,0,.8);
				-moz-box-shadow: 0 0 8px rgba(0,0,0,.8);
				box-shadow: 0 0 8px rgba(0,0,0,.8);
			}
		}
	}

	.header-img {
		width: 50%;
		position: relative;
	}
}

#pre-footer {
	display: none;
}

/* -- MAIN CONTENT -- */
body:not(.index) #main {
	padding-top: 5%;
}

.highlighted {
	padding-top: 5%;
	h1 {
		font-size: 2.5rem;
	}
	img {
		width: 110%;
	}
	a {
		color: #f3ce46;
		&:hover {
			text-decoration: underline;
		}
	}
}


.terms, .privacy {
	background: #f6f6f6;
	h5 h6 {
		text-transform: uppercase;
	}
}

.stickers {
	h5 {
		margin-bottom: 20px;
		line-height: 1;
		text-transform: uppercase;
		color: #19443c;
	}
	img, .coming-soon, .hmlogo {
		width: 120px;
	}
	.coming-soon {
		display: block;
		margin: 0 auto;
	}
}

.local-set, .school-set {
	.logos {
		width: 220px;
		overflow: hidden;
		margin: 0 2%;
		display: inline-block;
		font-size: 0.8rem;
	}
	img {
		width: 220px;
	}
}

.large-12.columns {
	margin-right: 0;
  	padding-right: 0;
}

#mc_embed_signup {
	form {
	text-align: center!important;
	}

	div.response {
		width: 100%;
	}

	h2 {
		font-size: 2rem!important;
	}

	.button {
		background-color: #f47d1c!important;
	}

	.button:hover {
		background-color: #f4862c!important;
	}

	div.response {
		margin: 0 auto!important;
		padding-bottom: 2em!important;
		float: none!important;
		color: #006b63!important;
	}
}


#footer-inner {
	a {
	color: #006b63;
	}

	a:hover {
		text-decoration: underline;
	}
}


/* COMING SOON -- HIDE WHEN NOT COMING SOON */
body {
	background: #46bfa9;
}
.section-wrapper.splash {
	height: calc(100vh - 160px);
	background: #ffffff;
}
#comingsoon {
	height: 100%;
	display: flex;
	align-items: center;
}
#comingsoon > .column {
	margin-top: -5vh;
}
.header-img {
	width: 80%;
	max-width: 720px;
}
footer {
	background: #46bfa9;
	color: #ffffff;
}
.invisible {
	height: 1px;
}

/* -- MAILCHIMP SIGNUP -- */
/* This is using the Email Optin module */
.mailinglist {
	padding: 30px !important;
}
.mailinglist h2 {
	margin-bottom: 0;
	text-align: left;
	@include breakpoint(medium) {
		text-align: right;
	}
	@include breakpoint(small only) {
		margin-bottom: 10px;
		text-align: center;
	}
}
#mc_embed_signup form {
	padding: 0;
}
#mc_embed_signup_scroll {
	display: flex;
	align-items: center;
}
#mc_embed_signup input.email {
	margin: 0 !important;
}
#mc_embed_signup input.button {
	width: 120px !important;
	height: 39px;
	margin-bottom: 0 !important;
}


.success h2,
.success h3,
.success p {
    color: #000 !important;
	font-size: 20px;
}
@include breakpoint(medium) {
	#mailing-list.row {
		display: flex;
		align-items: center;
	}
	#mc_embed_signup input.email {
		flex-basis: calc(100% - 130px);
	}
	#mc_embed_signup input.button {
		flex-basis: 130px;
		margin-left: 10px !important;
	}
	
}
@include breakpoint(small only) {
	#mc_embed_signup input.email {
		flex-basis: 100%;
	}
}
